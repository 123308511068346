html {
  overflow-x: auto;
  min-width: 360px;
  min-height: 100vh;
}

body {
  @include background();
  // min-height: 100vh;
  // background: url(../images/leather_1.png);
  // background-color: $background;
  // @media screen and (max-width: $tablet) {
  //   background: red;
  // }
  // @media screen and (max-aspect-ratio: 1/1) {
  //   background: blue;
  // }
}

.content {
  a {
    text-decoration: underline;
  }
}


.navbar-menu {
  background-color: rgba($color: #000000, $alpha: 0.2);
  background: rgba(white, 0.8) !important;
  a {
    color: $black;
    &:hover {
      background-color: white !important;
      color: $black !important;
    }
  }

  @include from($tablet) {
    display: block;
    opacity: 0;

    position: absolute;   /* or float: left; width: 100%;*/
    left: 0;
    right: 0;

    transform: translateY(-50%);
    transition: all $duration $easing;
    pointer-events: none;

    &.is-active {
      opacity: 1;
      transform: none;
      pointer-events: auto;
    }
  }

  @include mobile {
    position: absolute;
    display: block !important;
    height: 100vh;
    right: 100%;
    width: 100%;
    transition: right $duration $easing;
    &.is-active {
      right: 0;
    }
  }
}

.section {
  &.is-vertical {
    @include mobile {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }

  &.is-main {
    height: 100vh;
    padding-top: $navbar-height + 1rem;
  }

  &.is-full-screen {
    height: 100vh;
    padding: 1rem;
    padding-top: $navbar-height + 1.5rem;
    // @include mobile {
    //   padding-top: $navbar-height + 0.5rem;

    // }
    .commands {
      display: flex;
      flex-direction: column;
      // @include tablet {
        justify-content: space-around;
      // }
      // min-height: 100%;
      min-height: 85vh;
      margin-top: 0;
      align-items: center;
      .row {
        width: 100%;
      }
    }
  }
}

.level-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  max-width: $widescreen;
  margin: auto;
  > .level:not(:last-child) {
    margin-bottom: 0;
  }
  .level-item {
    margin: 0 1rem;
  }
}

.field:not(:last-child) {
  margin-bottom: 2rem;
}