@mixin background() {
  background: url(../images/leather_1.png);
  background-color: $background;
}

@mixin table-space() {
  position: relative;
  height: 2 * ($card_height + $card_space);
  width: 100%;
  margin-bottom: 4rem;
}

@mixin compact-mode() {
  @media screen and (max-width: $tablet), screen and (max-aspect-ratio: 1/1) {
    @content;
  }
}