// This overrides bulma default .section utility to extend it.
// This is kept separated in case it gets merged
// Here's the associated issue comment: https://github.com/jgthms/bulma/issues/451#issuecomment-594071198

$section-padding-vertical: 3rem !default
$section-padding-horizontal: 1.5rem !default
$section-padding: $section-padding-vertical $section-padding-horizontal !default
$section-padding-small-vertical: 1.5rem !default
$section-padding-small-horizontal: $section-padding-horizontal !default
$section-padding-small: $section-padding-small-vertical $section-padding-small-horizontal !default
$section-padding-medium-vertical: 9rem !default
$section-padding-medium-horizontal: 2rem !default
$section-padding-medium: $section-padding-medium-vertical $section-padding-medium-horizontal !default
$section-padding-large-vertical: 18rem !default
$section-padding-large-horizontal: 3rem !default
$section-padding-large: $section-padding-large-horizontal $section-padding-large-horizontal !default

.section
  padding: $section-padding
  &.is-vertical
    padding: $section-padding-vertical 0
  &.is-horizontal
    padding: 0 $section-padding-horizontal
  // Responsiveness
  +desktop
    // Sizes
    &.is-small
      padding: $section-padding-small
      &.is-vertical
        padding: $section-padding-small-vertical 0
      &.is-horizontal
        padding: 0 $section-padding-small-horizontal
    &.is-medium
      padding: $section-padding-medium
      &.is-vertical
        padding: $section-padding-medium-vertical 0
      &.is-horizontal
        padding: 0 $section-padding-medium-horizontal
    &.is-large
      padding: $section-padding-large
      &.is-vertical
        padding: $section-padding-large-vertical 0
      &.is-horizontal
        padding: 0 $section-padding-large-horizontal
