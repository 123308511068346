$card_ratio: 1.39375;
// $card_width: 8rem;
// $card_height: $card_width * $card_ratio;
$card_height: 16vh;
$card_width: $card_height / $card_ratio;
$card_space: $card_width / 4;

$tablet: 600px;

$white: white;
$black: black;
$red: #d40000;
$green: #02731E;
$blue: #131f67;
$yellow: #f1c40f;

$text: $white;
$text-strong: darken($text, 5%);
$background: $green;
$primary: $red;
$input-color: $black;
$link: darken($white, 20%);
$link-hover: $blue;

$box-background-color: $green;
$modal-content-width: 100%;

$navbar-background-color: transparent;
$navbar-breakpoint: 10000px;
$navbar-item-color: $white;
$navbar-item-hover-color: darken($white, 10%);
$radius: 2rem;
$easing: ease-in-out;
$duration: .4s;

$table-color: hsl(0, 0%, 21%);
$table-background-color: $background;
$table-striped-row-even-background-color: hsl(0, 0%, 98%);

@import '~bulma/sass/utilities/_all';
@import 'mixins';